import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/apps/blog/src/components/PageLayout/PageLayout.tsx";
import { graphql } from 'gatsby';
import { Container, BlogList, PageOffset } from 'components';
export const BlogIndexQuery = graphql`
    query BlogIndexQuery {
        allMdx(
            sort: { fields: [frontmatter___sort], order: DESC }
            filter: { frontmatter: { published: { eq: true }, kind: { eq: "Blog" } } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date
                        slug
                        published
                        description
                        images {
                            image {
                                childImageSharp {
                                    gatsbyImageData(
                                        layout: CONSTRAINED
                                        width: 3000
                                        quality: 80
                                        placeholder: BLURRED
                                    )
                                }
                            }
                            isCover
                        }
                    }
                }
            }
        }
    }
`;
export const _frontmatter = {};
const layoutProps = {
  BlogIndexQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BlogList data={props.data.allMdx.edges} showSearchBar mdxType="BlogList" />
    <PageOffset mdxType="PageOffset" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      